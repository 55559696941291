import React from 'react';

import { Box, NavigationLink } from '@nestoca/ui';
import Link from 'next/link';

import { Tooltip } from 'components/tooltip';

import styles from './secondary-sidebar.module.scss';
import { LinkOptions } from './types';

type Props = {
    linksOptions: LinkOptions[];
};

export const Content = ({ linksOptions }: Props) => {
    return (
        <Box id="sectionsSelector" className={styles.list} as="ul">
            {linksOptions.map((linkOptions) => {
                const {
                    id,
                    active,
                    dataTestId,
                    disabled,
                    error,
                    href,
                    onClick,
                    subText,
                    text,
                    tooltipMessage,
                    tooltipType = 'light',
                    visible = true,
                } = linkOptions;

                return (
                    visible && (
                        <Tooltip
                            key={id}
                            content={tooltipMessage}
                            placement="right"
                            offset={[0, 24]}
                            disabled={!tooltipMessage}
                            type={tooltipType}
                        >
                            <Box as="li">
                                <Link href={href} legacyBehavior>
                                    <NavigationLink
                                        data-testid={dataTestId}
                                        active={active}
                                        subText={subText}
                                        error={error}
                                        disabled={disabled}
                                        onClick={onClick || null}
                                    >
                                        {text}
                                    </NavigationLink>
                                </Link>
                            </Box>
                        </Tooltip>
                    )
                );
            })}
        </Box>
    );
};
