import React, { Suspense } from 'react';

import { Skeleton, Sidebar } from '@nestoca/ui';

import { ScrollArea } from 'components/scroll-area';

import { Content } from './content';
import styles from './secondary-sidebar.module.scss';
import { SecondarySidebarProps } from './types';

const SecondarySidebarSkeleton = () => (
    <Sidebar className={styles.sidebar_secondary} variant="secondary">
        <Sidebar.Content>
            <ScrollArea>
                <Skeleton animate count={10} height={40} />
            </ScrollArea>
        </Sidebar.Content>
    </Sidebar>
);

export const SecondarySidebar = ({
    linksOptions,
    footer,
}: SecondarySidebarProps) => (
    <Suspense fallback={<SecondarySidebarSkeleton />}>
        <Sidebar className={styles.sidebar_secondary} variant="secondary">
            <Sidebar.Content>
                <ScrollArea>
                    <Content linksOptions={linksOptions} />
                </ScrollArea>
            </Sidebar.Content>
            {footer && <Sidebar.Footer>{footer}</Sidebar.Footer>}
        </Sidebar>
    </Suspense>
);
