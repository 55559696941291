import React from 'react';

import { Button, Flex } from '@nestoca/ui';
import { useFormContext } from 'react-hook-form';

import { useI18n } from 'providers/i18n/use-i18n';

export const ModalFormActions = ({ closeModal }) => {
    const { i18n } = useI18n();

    const {
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <Flex
            align="center"
            justify="end"
            gap={4}
            as="footer"
            style={{ gridColumn: '1 / -1' }}
        >
            <Button
                data-testid="cancel-button"
                name="cancel"
                variant="ghost"
                onClick={closeModal}
            >
                {i18n._('cancel')}
            </Button>
            <Button
                data-testid="save-button"
                name="save"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
            >
                {i18n._('save')}
            </Button>
        </Flex>
    );
};
