import React from 'react';

import { Trans } from '@lingui/react';
import { Box } from 'reflexbox';

export const ConfirmDeleteText = () => (
    <>
        <Box fontSize={3}>
            <Trans id="confirm.defaultContent" />
        </Box>
        <Box fontSize={2} mt={3}>
            <Trans id="confirm.defaultCopy" />
        </Box>
    </>
);
