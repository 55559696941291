import React, { forwardRef } from 'react';

import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { shadow, variant } from 'styled-system';

import type { BoxProps } from 'reflexbox/styled-components';
import type { ShadowProps, ResponsiveValue } from 'styled-system';
import type { Assign } from 'types/components';

interface BadgeOwnProps extends BoxProps, ShadowProps {
    // As prop is defined into BoxProps
    // as?: React.ElementType;
    variant?: ResponsiveValue<Variant>;
}

export type BadgeProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    BadgeOwnProps
>;

export type Variant =
    | 'primary'
    | 'secondary'
    | 'largeWarning'
    | 'large'
    | 'DOCUMENT_EMPTY'
    | 'DOCUMENT_RECEIVED'
    | 'DOCUMENT_BROKER_APPROVED'
    | 'DOCUMENT_BROKER_UNAPPROVED'
    | 'DOCUMENT_LENDER_ACCEPTED'
    | 'DOCUMENT_DISABLED'
    | 'dashboard';

export const BadgeStyled = styled(Box)`
    display: inline-block;
    vertical-align: baseline;
    font-weight: bold;
    white-space: nowrap;
    font-size: 10px;
    padding-left: 6px;
    padding-right: 6px;
    color: white;
    background-color: 'black';
    max-width: fit-content;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 25%);
    border-radius: 8px;

    ${variant({
        scale: 'badges',
        prop: 'variant',
    })}
    ${shadow}
`;

export type BadgeType = BoxProps & BadgeProps;

export const Badge = forwardRef(
    (
        { variant = 'primary', ...rest }: BadgeType,
        ref: React.Ref<HTMLDivElement>
    ) => <BadgeStyled ref={ref} boxShadow="none" variant={variant} {...rest} />
);
