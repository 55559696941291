import React from 'react';

import { useRecoilState } from 'recoil';

import {
    LinkOptions,
    SecondarySidebar,
} from 'components/sidebar/secondary-sidebar';
import { useI18n } from 'providers/i18n/use-i18n';
import { getSelectedDashboard } from 'store/dashboard';

export const DashboardSidebar = () => {
    const { i18n } = useI18n();

    const [activeDashboard, setActiveDashboard] =
        useRecoilState(getSelectedDashboard);

    const linksOptions: LinkOptions[] = [
        {
            id: 'subnav-advisor',
            active: activeDashboard === 'ADVISOR',
            dataTestId: 'subnav-advisor',
            href: '',
            onClick: () => setActiveDashboard('ADVISOR'),
            text: i18n._('Advisor'),
        },
        {
            id: 'subnav-underwriter',
            active: activeDashboard === 'UNDERWRITER',
            dataTestId: 'subnav-underwriter',
            href: '',
            onClick: () => setActiveDashboard('UNDERWRITER'),
            text: i18n._('Underwriter'),
        },
    ];

    return <SecondarySidebar linksOptions={linksOptions} />;
};
